import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const RegisterPage = () => (
  <Layout>
    <SEO title="Register" />
    {/* <p className="mt-8 text-2xl font-bold text-center">
      Registration is coming soon!
    </p>
    <p>
      If you have any questions in the meantime, please feel free to contact us.
    </p> */}
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSdbaKWF1c0DeiSzVTMRKoetyhZEK4M3bLYyjWZfqaFarBxluw/viewform?embedded=true"
      width="100%"
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      className="bg-gray-100 min-h-screen-nav"
      title="SBMUN VIII Registration"
    >
      Loading…
    </iframe>
  </Layout>
)

export default RegisterPage
